<script setup>
import {ref} from 'vue';
import {Head, Link, router} from '@inertiajs/vue3';
import Banner from '@/Components/Banner.vue';
import {useColorMode} from '@vueuse/core'
import {
  FwbButton,
  FwbNavbar,
} from 'flowbite-vue'

defineProps({
  title: String,
});

let colourMode = useColorMode()

function toggleColorMode() {
  colourMode.value = colourMode.value === 'dark' ? 'light' : 'dark'
}

const showingNavigationDropdown = ref(false);

const switchToTeam = (team) => {
  router.put(route('current-team.update'), {
    team_id: team.id,
  }, {
    preserveState: false,
  });
};

const logout = () => {
  router.post(route('logout'));
};
</script>

<template>
  <div>
    <Head :title="title"/>

    <Banner/>

    <div class="min-h-screen bg-gray-100 dark:bg-gray-900">
      <!--<nav class="bg-white dark:bg-gray-800">
          &lt;!&ndash; Primary Navigation Menu &ndash;&gt;
          <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div class="flex justify-between h-16">
                  <div class="flex">
                      &lt;!&ndash; Logo &ndash;&gt;
                      <div class="shrink-0 flex items-center">
                          <Link :href="route('home')">
                              <ApplicationMark class="block h-9 w-auto"/>
                          </Link>
                      </div>
                  </div>

                  <template v-if="$page.props.auth.user">
                      <div class="hidden sm:flex sm:items-center sm:ms-6">
                          <NavLink :href="route('dashboard')" :active="route().current('dashboard')">
                              Dashboard
                          </NavLink>
                          <div class="ms-3 relative">
                              &lt;!&ndash; Teams Dropdown &ndash;&gt;
                              <Dropdown v-if="$page.props.jetstream.hasTeamFeatures" align="right" width="60">
                                  <template #trigger>
                                  <span class="inline-flex rounded-md">
                                      <button type="button"
                                              class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 dark:text-gray-400 bg-white dark:bg-gray-800 hover:text-gray-700 dark:hover:text-gray-300 focus:outline-none focus:bg-gray-50 dark:focus:bg-gray-700 active:bg-gray-50 dark:active:bg-gray-700 transition ease-in-out duration-150">
                                          {{ $page.props.auth.user.current_team.name }}

                                          <svg class="ms-2 -me-0.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                               fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                               stroke="currentColor">
                                              <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"/>
                                          </svg>
                                      </button>
                                  </span>
                                  </template>

                                  <template #content>
                                      <div class="w-60">
                                          &lt;!&ndash; Team Management &ndash;&gt;
                                          <div class="block px-4 py-2 text-xs text-gray-400">
                                              Manage Team
                                          </div>

                                          &lt;!&ndash; Team Settings &ndash;&gt;
                                          <DropdownLink
                                              :href="route('teams.show', $page.props.auth.user.current_team)">
                                              Team Settings
                                          </DropdownLink>

                                          <DropdownLink v-if="$page.props.jetstream.canCreateTeams"
                                                        :href="route('teams.create')">
                                              Create New Team
                                          </DropdownLink>

                                          &lt;!&ndash; Team Switcher &ndash;&gt;
                                          <template v-if="$page.props.auth.user.all_teams.length > 1">
                                              <div class="border-t border-gray-200 dark:border-gray-600"/>

                                              <div class="block px-4 py-2 text-xs text-gray-400">
                                                  Switch Teams
                                              </div>

                                              <template v-for="team in $page.props.auth.user.all_teams"
                                                        :key="team.id">
                                                  <form @submit.prevent="switchToTeam(team)">
                                                      <DropdownLink as="button">
                                                          <div class="flex items-center">
                                                              <svg
                                                                  v-if="team.id == $page.props.auth.user.current_team_id"
                                                                  class="me-2 h-5 w-5 text-green-400"
                                                                  xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                  viewBox="0 0 24 24" stroke-width="1.5"
                                                                  stroke="currentColor">
                                                                  <path stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                              </svg>

                                                              <div>{{ team.name }}</div>
                                                          </div>
                                                      </DropdownLink>
                                                  </form>
                                              </template>
                                          </template>
                                      </div>
                                  </template>
                              </Dropdown>
                          </div>

                          &lt;!&ndash; Settings Dropdown &ndash;&gt;
                          <div class="ms-3 relative">
                              <Dropdown align="right" width="48">
                                  <template #trigger>
                                      <button v-if="$page.props.jetstream.managesProfilePhotos"
                                              class="flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition">
                                          <img class="h-8 w-8 rounded-full object-cover"
                                               :src="$page.props.auth.user.profile_photo_url"
                                               :alt="$page.props.auth.user.name">
                                      </button>

                                      <span v-else class="inline-flex rounded-md">
                                      <button type="button"
                                              class="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 dark:text-gray-400 bg-white dark:bg-gray-800 hover:text-gray-700 dark:hover:text-gray-300 focus:outline-none focus:bg-gray-50 dark:focus:bg-gray-700 active:bg-gray-50 dark:active:bg-gray-700 transition ease-in-out duration-150">
                                          {{ $page.props.auth.user.name }}

                                          <svg class="ms-2 -me-0.5 h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                               fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                               stroke="currentColor">
                                              <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"/>
                                          </svg>
                                      </button>
                                  </span>
                                  </template>

                                  <template #content>
                                      &lt;!&ndash; Account Management &ndash;&gt;
                                      <div class="block px-4 py-2 text-xs text-gray-400">
                                          Manage Account
                                      </div>

                                      <DropdownLink :href="route('profile.show')">
                                          Profile
                                      </DropdownLink>

                                      <DropdownLink v-if="$page.props.jetstream.hasApiFeatures"
                                                    :href="route('api-tokens.index')">
                                          API Tokens
                                      </DropdownLink>

                                      <div class="border-t border-gray-200 dark:border-gray-600"/>

                                      &lt;!&ndash; Authentication &ndash;&gt;
                                      <form @submit.prevent="logout">
                                          <DropdownLink as="button">
                                              Log Out
                                          </DropdownLink>
                                      </form>
                                  </template>
                              </Dropdown>
                          </div>
                      </div>
                  </template>
                  <template v-else>
                      <div class="hidden sm:flex sm:items-center sm:ms-6">
                          <div class="ms-3 relative">
                              <a
                                  href="#"
                                  class="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium leading-5 text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300 hover:border-gray-300 dark:hover:border-gray-700 focus:outline-none focus:text-gray-700 dark:focus:text-gray-300 focus:border-gray-300 dark:focus:border-gray-700 transition duration-150 ease-in-out"
                                  @click.prevent="toggleColorMode"
                              >
                                  {{ mode }}
                              </a>
                          </div>
                          <div class="ms-3 relative">
                              <NavLink :href="route('login')" :active="route().current('login')">
                                  Login
                              </NavLink>
                          </div>
                          <template v-if="$page.props.jetstream.canRegister">
                              <div class="ms-3 relative">
                                  <NavLink :href="route('register')" :active="route().current('register')">
                                      Register
                                  </NavLink>
                              </div>
                          </template>
                      </div>
                  </template>

                  &lt;!&ndash; Hamburger &ndash;&gt;
                  <div class="-me-2 flex items-center sm:hidden">
                      <button
                          class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 dark:text-gray-500 hover:text-gray-500 dark:hover:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-900 focus:outline-none focus:bg-gray-100 dark:focus:bg-gray-900 focus:text-gray-500 dark:focus:text-gray-400 transition duration-150 ease-in-out"
                          @click="showingNavigationDropdown = ! showingNavigationDropdown">
                          <svg
                              class="h-6 w-6"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 24 24"
                          >
                              <path
                                  :class="{'hidden': showingNavigationDropdown, 'inline-flex': ! showingNavigationDropdown }"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M4 6h16M4 12h16M4 18h16"
                              />
                              <path
                                  :class="{'hidden': ! showingNavigationDropdown, 'inline-flex': showingNavigationDropdown }"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M6 18L18 6M6 6l12 12"
                              />
                          </svg>
                      </button>
                  </div>
              </div>
          </div>

          &lt;!&ndash; Responsive Navigation Menu &ndash;&gt;
          <div :class="{'block': showingNavigationDropdown, 'hidden': ! showingNavigationDropdown}"
               class="sm:hidden">
              <div class="pt-2 pb-3 space-y-1">
                  <ResponsiveNavLink :href="route('dashboard')" :active="route().current('dashboard')">
                      Dashboard
                  </ResponsiveNavLink>
              </div>

              &lt;!&ndash; Responsive Settings Options &ndash;&gt;
              <template v-if="$page.props.auth.user">
                  <div class="pt-4 pb-1 border-t border-gray-200 dark:border-gray-600">
                      <div class="flex items-center px-4">
                          <div v-if="$page.props.jetstream.managesProfilePhotos" class="shrink-0 me-3">
                              <img class="h-10 w-10 rounded-full object-cover"
                                   :src="$page.props.auth.user.profile_photo_url"
                                   :alt="$page.props.auth.user.name">
                          </div>

                          <div>
                              <div class="font-medium text-base text-gray-800 dark:text-gray-200">
                                  {{ $page.props.auth.user.name }}
                              </div>
                              <div class="font-medium text-sm text-gray-500">
                                  {{ $page.props.auth.user.email }}
                              </div>
                          </div>
                      </div>

                      <div class="mt-3 space-y-1">
                          <ResponsiveNavLink :href="route('profile.show')"
                                             :active="route().current('profile.show')">
                              Profile
                          </ResponsiveNavLink>

                          <ResponsiveNavLink v-if="$page.props.jetstream.hasApiFeatures"
                                             :href="route('api-tokens.index')"
                                             :active="route().current('api-tokens.index')">
                              API Tokens
                          </ResponsiveNavLink>

                          &lt;!&ndash; Authentication &ndash;&gt;
                          <form method="POST" @submit.prevent="logout">
                              <ResponsiveNavLink as="button">
                                  Log Out
                              </ResponsiveNavLink>
                          </form>

                          &lt;!&ndash; Team Management &ndash;&gt;
                          <template v-if="$page.props.jetstream.hasTeamFeatures">
                              <div class="border-t border-gray-200 dark:border-gray-600"/>

                              <div class="block px-4 py-2 text-xs text-gray-400">
                                  Manage Team
                              </div>

                              &lt;!&ndash; Team Settings &ndash;&gt;
                              <ResponsiveNavLink :href="route('teams.show', $page.props.auth.user.current_team)"
                                                 :active="route().current('teams.show')">
                                  Team Settings
                              </ResponsiveNavLink>

                              <ResponsiveNavLink v-if="$page.props.jetstream.canCreateTeams"
                                                 :href="route('teams.create')"
                                                 :active="route().current('teams.create')">
                                  Create New Team
                              </ResponsiveNavLink>

                              &lt;!&ndash; Team Switcher &ndash;&gt;
                              <template v-if="$page.props.auth.user.all_teams.length > 1">
                                  <div class="border-t border-gray-200 dark:border-gray-600"/>

                                  <div class="block px-4 py-2 text-xs text-gray-400">
                                      Switch Teams
                                  </div>

                                  <template v-for="team in $page.props.auth.user.all_teams" :key="team.id">
                                      <form @submit.prevent="switchToTeam(team)">
                                          <ResponsiveNavLink as="button">
                                              <div class="flex items-center">
                                                  <svg v-if="team.id == $page.props.auth.user.current_team_id"
                                                       class="me-2 h-5 w-5 text-green-400"
                                                       xmlns="http://www.w3.org/2000/svg" fill="none"
                                                       viewBox="0 0 24 24" stroke-width="1.5"
                                                       stroke="currentColor">
                                                      <path stroke-linecap="round" stroke-linejoin="round"
                                                            d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
                                                  </svg>
                                                  <div>{{ team.name }}</div>
                                              </div>
                                          </ResponsiveNavLink>
                                      </form>
                                  </template>
                              </template>
                          </template>
                      </div>
                  </div>
              </template>
          </div>
      </nav>-->
      <fwb-navbar>
        <template #default="{isShowMenu}">
          <fwb-button class="ml-auto !border-none" color="light" square @click="toggleColorMode">
            <template v-if="colourMode === 'dark'">
              <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                   width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M12 21a9 9 0 0 1-.5-17.986V3c-.354.966-.5 1.911-.5 3a9 9 0 0 0 9 9c.239 0 .254.018.488 0A9.004 9.004 0 0 1 12 21Z"/>
              </svg>
            </template>
            <template v-else>
              <svg class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                   width="24" height="24" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M12 5V3m0 18v-2M7.05 7.05 5.636 5.636m12.728 12.728L16.95 16.95M5 12H3m18 0h-2M7.05 16.95l-1.414 1.414M18.364 5.636 16.95 7.05M16 12a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"/>
              </svg>
            </template>
          </fwb-button>
        </template>
      </fwb-navbar>

      <!-- Page Heading -->
      <header v-if="$slots.header" class="bg-white dark:bg-gray-800 shadow">
        <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <slot name="header"/>
        </div>
      </header>

      <slot name="header"></slot>

      <!-- Page Content -->
      <main>
        <slot/>
      </main>
    </div>
  </div>
</template>
